import React, { useState, useEffect } from "react";
import cn from "classnames";
import styles from "./Action.module.sass";
import { Range, getTrackBackground } from "react-range";
import Icon from "../../../../../components/Icon";
import { useDispatch, useSelector } from "react-redux";
import helper from "utils/helper.js";
import { Link } from "react-router-dom";
import { ORDER_TYPE, POSITION_TYPE } from "utils/types.js";
import Toggle from "ui/Toggle/index.jsx";
import LeverageModal from "components/LeverageModal/index.jsx";
import AmountSelectOptions from "components/AmountSelectOptions.jsx/index.jsx";
import { setLeverage } from "store/slices/futuresSlice.js";
import toast from "react-hot-toast";
import api from "utils/api.js";
import { deci } from "utils/decimal.js";
import bride from "utils/php_bridge.js";
import { useTranslation } from "react-i18next";

const Action = ({ content, market, stop, limit, classButton, isBuy }) => {
  //TODO : Handle signup and login button clicks
  const { t } = useTranslation();
  const dispatch = useDispatch();
  const isLoggedIn = useSelector((state) => state.user.loggedIn);
  const baseTokenSymbol = useSelector(
    (state) => state.symbolData.pairInfo.baseTokenSymbol
  );
  const quoteTokenSymbol = useSelector(
    (state) => state.symbolData.pairInfo.quoteTokenSymbol
  );
  const maxLeverage = useSelector(
    (state) => state.symbolData.pairInfo.maxLeverage
  );
  const userBalance = useSelector((state) => state.user.balance);
  const tradingPair = useSelector((state) => state.symbolData.symbol);
  const currentPrice = useSelector((state) => state.symbolData.price);
  const precesion = useSelector((state) => state.symbolData.precesion);
  const defaultLeverage = useSelector(
    (state) => state.user.user.defaultLeverage
  );
  const leverage = useSelector((state) => state.futures.leverage);

  const [limitPrice, setLimitPrice] = useState("");
  const [stopPrice, setStopPrice] = useState("");
  const [orderValue, setOrderValue] = useState("");
  const [leveragedValue, setLeveragedValue] = useState("");
  const [values, setValues] = useState([10]);

  const [autoPriceEnabled, setAutoPriceEnabled] = useState(true);

  const [showLevergaeModal, setShowLeverageModal] = useState(false);

  const stepPrice = 1;
  const minPrice = 0;
  const maxPrice = 100;

  function isValidNumber(str) {
    const regex = /^-?\d+(\.\d+)?$|^-?\d+\.$/;
    return regex.test(str);
  }

  function updateIfValid(value, trigger) {
    if (isValidNumber(value) || value.length === 0) {
      console.log("valid", value);
      trigger(value);
    } else {
      console.log("notvalid", value);
    }
  }
  const placeOrder = async (isLong) => {
    try {
      //TODO : verify and show popup for valid parameters
      // Ex : limit , stop , quantity

      // symbol,
      // baseTokenAmount,
      // quoteTokenAmount,
      // orderType,
      // tradeType,
      // limitPrice,
      // stopPrice,

      const body = {
        symbol: tradingPair,
        amountUsd: leveragedValue,
        leverage: leverage,
        orderType: stop
          ? ORDER_TYPE.stop_market
          : limit
          ? ORDER_TYPE.limit
          : ORDER_TYPE.market,
        positionType: isLong ? POSITION_TYPE.long : POSITION_TYPE.short,
        limitPrice: limitPrice,
        markPrice: stopPrice,
      };
      toast.promise(
        api.placeOrderFuture(body),
        {
          success: (result) => {
            t("actions.order_placed");
          },
          error: (err) => err.toString(),
          loading: t("actions.processing"),
        },
        {
          style: {
            minWidth: "250px",
          },
        }
      );
    } catch (error) {
      console.log(error);
      toast.error(t("toast_messages.something_wrong"));
    }
  };

  const updateInputValues = (values) => {
    if (!userBalance[quoteTokenSymbol]) return;
    setOrderValue(
      (values[0] * parseFloat(userBalance[quoteTokenSymbol].futures)) / 100
    );
  };

  const handleChangeLeverage = () => {
    if (!isLoggedIn) {
      toast(t("toast_messages.login_required"), {
        duration: 2000,
      });
      return;
    }
    setShowLeverageModal(true);
  };

  useEffect(() => {
    if (!userBalance[quoteTokenSymbol]) return;
    let percentage =
      (orderValue * 100) / parseFloat(userBalance[quoteTokenSymbol].futures);
    setValues([percentage]);
  }, [orderValue, userBalance, quoteTokenSymbol]);

  useEffect(() => {
    if (autoPriceEnabled) {
      setLimitPrice(helper.formatValue(currentPrice, precesion));
    }
  }, [currentPrice, autoPriceEnabled, precesion]);

  useEffect(() => {
    if (leverage > maxLeverage) {
      dispatch(setLeverage(maxLeverage));
    }
  }, [defaultLeverage, leverage, dispatch, maxLeverage]);

  useEffect(() => {
    if (!deci(leverage)) {
      return;
    }
    setLeveragedValue(deci(leverage).mul(deci(orderValue) ?? 0));
  }, [leverage, orderValue]);

  return (
    <>
      <div className={styles.head}>
        <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
          <div>{t("actions.available_balance")}</div>
          <Icon name="wallet" size="16" className={cn("styled-icon")} />{" "}
        </div>

        {userBalance[quoteTokenSymbol] && userBalance[baseTokenSymbol] && (
          <div style={{ fontWeight: "500" }}>
            {helper.formatValue(
              isBuy
                ? userBalance[quoteTokenSymbol].futures
                : userBalance[baseTokenSymbol].futures,
              9
            )}
          </div>
        )}
      </div>
      <div
        style={{
          display: "flex",
          justifyContent: "space-between",
        }}
      >
        <div className="">
          <Toggle
            checked={autoPriceEnabled}
            round={true}
            onChange={(e) => {
              setAutoPriceEnabled(e.target.checked);
            }}
          />
          <label
            style={{
              marginLeft: "8px",
            }}
          >
            {t("form.auto_price")}
          </label>
        </div>
        <span
          className="category-green"
          onClick={handleChangeLeverage}
          style={{
            cursor: "pointer",
          }}
        >
          {leverage}x
        </span>
      </div>
      {stop && (
        <div>
          <div className={styles.label}>{t("actions.stop")}</div>
          <label className={styles.field}>
            <input
              className={styles.input}
              type="text"
              name="stop"
              required
              value={stopPrice}
              onChange={(evt) => updateIfValid(evt.target.value, setStopPrice)}
            />
            <div className={styles.currency}>{quoteTokenSymbol}</div>
          </label>
        </div>
      )}
      {limit && (
        <div>
          <div className={styles.label}>{t("actions.limit")}</div>
          <label className={styles.field}>
            <input
              className={styles.input}
              type="text"
              name="limit"
              required
              value={limitPrice}
              onChange={(evt) => updateIfValid(evt.target.value, setLimitPrice)}
            />
            <div className={styles.currency}>{quoteTokenSymbol}</div>
          </label>
        </div>
      )}
      <div>
        <div className={styles.label}>{t("actions.amount")}</div>
        <label className={styles.field}>
          <input
            className={styles.input}
            type="text"
            name="amount"
            required
            value={orderValue}
            onChange={(evt) => updateIfValid(evt.target.value, setOrderValue)}
          />
          <div className={styles.currency}>
            {isBuy ? quoteTokenSymbol : baseTokenSymbol}
          </div>
        </label>
      </div>
      <div>
        <div className={styles.label}>{t("table.order_value")}</div>
        <label className={styles.field}>
          <input
            className={styles.input}
            type="text"
            name="amount"
            required
            readOnly
            value={leveragedValue}
          />
          <div className={styles.currency}>{quoteTokenSymbol}</div>
        </label>
      </div>

      <Range
        values={values}
        step={stepPrice}
        min={minPrice}
        max={maxPrice}
        onChange={(values) => {
          setValues(values);
          updateInputValues(values);
        }}
        renderTrack={({ props, children }) => (
          <div
            onMouseDown={props.onMouseDown}
            onTouchStart={props.onTouchStart}
            style={{
              ...props.style,
              height: "36px",
              display: "flex",
              width: "100%",
            }}
          >
            <div
              ref={props.ref}
              style={{
                height: "2px",
                width: "100%",
                borderRadius: "1px",
                background: getTrackBackground({
                  values,
                  colors: ["#3772FF", "#E6E8EC"],
                  min: minPrice,
                  max: maxPrice,
                }),
                alignSelf: "center",
              }}
            >
              {children}
            </div>
          </div>
        )}
        renderThumb={({ props, isDragged }) => (
          <div
            {...props}
            style={{
              ...props.style,
              height: "18px",
              width: "18px",
              borderRadius: "50%",
              backgroundColor: "#F4F5F6",
              border: "4px solid #777E90",
              boxShadow: "0px 8px 16px -8px rgba(15, 15, 15, 0.2)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {isDragged && (
              <div
                style={{
                  position: "absolute",
                  top: "-27px",
                  color: "#FCFCFD",
                  fontWeight: "600",
                  fontSize: "13px",
                  lineHeight: "16px",
                  fontFamily: "Poppins",
                  padding: "2px 6px",
                  borderRadius: "6px",
                  backgroundColor: "#777E90",
                }}
              >
                {values[0].toFixed(0)}%
              </div>
            )}
          </div>
        )}
      />

      <AmountSelectOptions
        percentage={values[0]}
        onPercentageChange={(_value) => {
          setValues([_value]);
          updateInputValues([_value]);
        }}
      />

      {isLoggedIn && (
        <div style={{ display: "flex", gap: "10px" }}>
          <button
            className={cn(classButton, styles.button)}
            onClick={() => {
              placeOrder(true);
            }}
            style={{
              background: "#58BD7D",
            }}
          >
            {t("actions.long")}
          </button>
          <button
            className={cn(classButton, styles.button)}
            onClick={() => {
              placeOrder(false);
            }}
            style={{
              background: "#FF6838",
            }}
          >
            {t("actions.short")}
          </button>
        </div>
      )}
      {!isLoggedIn && (
        <div className={styles.btns}>
          <Link
            className={cn("button", styles.button)}
            activeClassName={styles.active}
            to="/sign-up"
            onClick={(event) => {
              event.preventDefault();
              bride.route("/sign-up.php");
            }}
          >
            {t("actions.sign_up")}
          </Link>
          <Link
            style={{ marginTop: "8px" }}
            className={cn("button-stroke", styles.button)}
            activeClassName={styles.active}
            to="/sign-in"
            onClick={(event) => {
              event.preventDefault();
              bride.route("/sign-in.php");
            }}
          >
            {t("actions.login")}
          </Link>
        </div>
      )}
      <LeverageModal
        show={showLevergaeModal}
        onHide={() => setShowLeverageModal(false)}
      />
    </>
  );
};

export default Action;
