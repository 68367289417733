import { io } from "socket.io-client";
import { socketBaseUrl } from "utils/constants.js";
import Cookies from "js-cookie";

const URL = socketBaseUrl;

export const socket = io(URL);
console.log("sesn" , Cookies.get("PHPSESSID"))
export const userSocket = io(URL + "/user", {
  auth: {
    sessionId: Cookies.get("PHPSESSID"), // Provide the token
  },
});
