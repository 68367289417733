import React, { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import cn from "classnames";
import styles from "./Exchange.module.sass";
import Main from "../../components/TradingPairStatBar";
import Orderbook from "../../components/OrderBook";
import RecentTrades from "components/RecentTrades/index.js";
import Table from "./TableSpot";
import SpotFormWrapper from "./SpotFormWrapper";
import { useMediaQuery } from "react-responsive";
import MarketActivity from "../../components/MarketActivity";
import { useDispatch, useSelector } from "react-redux";
import { switchPair } from "store/symbolDataSlice.js";
import { TRADING_TYPE } from "utils/types.js";
import helper from "utils/helper.js";
import { useTranslation } from "react-i18next";
import AdvancedChart from "components/AdvancedChart/index.jsx";


const Spot = () => {
  const { t } = useTranslation();
  const navigation = [
    t("navigation.chart"),
    t("navigation.order_books"),
    t("navigation.trades")
  ];
  const { symbol } = useParams();
  const dispatch = useDispatch();
  const availablePairs = useSelector(
    (state) => state.symbolData.availablePairs
  );

  const [isSymbolUpdated, setSymbolUpdated] = useState(false);

  const currentPair = useSelector((state) => state.symbolData.symbol);
  const defaultSymbol = useSelector((state) => state.symbolData.defaultSymbol);

  useEffect(() => {
    if (!availablePairs || !availablePairs.length || isSymbolUpdated) return;
    //trigger update once after data is fetched
    setSymbolUpdated(true);
    if (!symbol || !availablePairs.find((item) => item.symbol === symbol)) {
      //invalid symbol use default
      //TODO : check if its necessary to call dispatch for updating symbol
      helper.updateUriSymbol(TRADING_TYPE.spot, defaultSymbol);
      return;
    }

    //update only if symbol is new one
    if (symbol !== currentPair) {
      dispatch(
        switchPair({
          tradeType: TRADING_TYPE.spot,
          symbol,
        })
      );
    }

    helper.updateUriSymbol(TRADING_TYPE.spot, symbol);
  }, [symbol, defaultSymbol, availablePairs]);

  const [activeIndex, setActiveIndex] = useState(0);
  const isTablet = useMediaQuery({ query: "(max-width: 1023px)" });

  return (
    <div className={styles.exchange}>
      <Main />
      <div className={styles.nav}>
        {navigation.map((x, index) => (
          <button
            className={cn(styles.link, {
              [styles.active]: index === activeIndex,
            })}
            onClick={() => setActiveIndex(index)}
            key={index}
          >
            {x}
          </button>
        ))}
      </div>
      {isTablet ? (
        <>
          <SpotFormWrapper />
          {activeIndex === 0 && (
            <div className={styles.box}>
              <AdvancedChart />
            </div>
          )}
          {activeIndex === 1 && (
            <div className={styles.box}>
              <Orderbook />
            </div>
          )}
          {activeIndex === 2 && (
            <div className={styles.box}>
              <RecentTrades />
            </div>
          )}
          <Table />
        </>
      ) : (
        <div className={styles.row}>
          <div className={styles.col}>
            <div className={styles.wrapper}>
              <AdvancedChart />
              <MarketActivity />
            </div>
            <div>
              <Table />
            </div>
          </div>
          <div className={styles.col}>
            <SpotFormWrapper />
          </div>
        </div>
      )}
    </div>
  );
};

export default Spot;
