import React, { useState, useEffect } from "react";
import cn from "classnames";
import styles from "./Action.module.sass";
import { Range, getTrackBackground } from "react-range";
import Icon from "../../../../../components/Icon";
import { useSelector } from "react-redux";
import helper from "utils/helper.js";
import { Link } from "react-router-dom";
import { BID_PREDICTION } from "utils/types.js";
import AmountSelectOptions from "components/AmountSelectOptions.jsx/index.jsx";
import toast from "react-hot-toast";
import api from "utils/api.js";
import { deci } from "utils/decimal.js";
import bride from "utils/php_bridge.js";
import { useTranslation } from "react-i18next";

const Action = ({}) => {
  const { t } = useTranslation();

  //TODO : Handle signup and login button clicks
  const isLoggedIn = useSelector((state) => state.user.loggedIn);
  const baseTokenSymbol = useSelector(
    (state) => state.symbolData.pairInfo.baseTokenSymbol
  );
  const quoteTokenSymbol = useSelector(
    (state) => state.symbolData.pairInfo.quoteTokenSymbol
  );

  const optionsReturnRate = useSelector(
    (state) => state.symbolData.pairInfo.optionsReturnRate
  );
  const minimumAmount = useSelector(
    (state) => state.symbolData.pairInfo.minAmountUsdOptionTrade
  );

  const userBalance = useSelector((state) => state.user.balance);

  const tradingPair = useSelector((state) => state.symbolData.symbol);
  const precesion = useSelector((state) => state.symbolData.precesion);

  const [orderValue, setOrderValue] = useState("");
  const [values, setValues] = useState([10]);
  const stepPrice = 1;
  const minPrice = 0;
  const maxPrice = 100;

  function isValidNumber(str) {
    const regex = /^-?\d+(\.\d+)?$|^-?\d+\.$/;
    return regex.test(str);
  }

  function updateIfValid(value, trigger) {
    if (isValidNumber(value) || value.length === 0) {
      let valueToUpdate = Math.max(Number(value), Number(minimumAmount));
      trigger(valueToUpdate);
      return valueToUpdate;
    }
    return null;
  }
  const placeOrder = async (isLong) => {
    try {
      //TODO : verify and show popup for valid parameters
      // if(deci(orderValue).lessThan(minimumAmount)){
      //   toast("")
      // }
      const body = {
        symbol: tradingPair,
        bidAmountUsd: orderValue,
        prediction: isLong ? BID_PREDICTION.long : BID_PREDICTION.short,
      };
      toast.promise(
        api.placeOptionsBid(body),
        {
          success: (result) => t("actions.order_placed"),
          error: (err) => err.toString(),
          loading: t("actions.processing"),
        },
        {
          style: {
            minWidth: "250px",
          },
        }
      );
    } catch (error) {
      console.log(error);
      toast.error(t("actions.something_went_wrong"));
    }
  };

  const updateInputValues = (values) => {
    if (!userBalance[quoteTokenSymbol]) return;
    let balance = deci(userBalance[quoteTokenSymbol].options);
    let updatedValue = updateIfValid(
      balance.mul(values[0]).div(100).toNumber(),
      setOrderValue
    );
    if (updatedValue != null) {
      setValues([deci(updatedValue).mul(100).div(balance).toNumber()]);
    }
  };

  useEffect(() => {
    if (!userBalance[quoteTokenSymbol]) return;
    let percentage =
      (orderValue * 100) / parseFloat(userBalance[quoteTokenSymbol].options);
    setValues([percentage]);
  }, [orderValue, quoteTokenSymbol, userBalance]);

  useEffect(() => {
    updateInputValues([0]);
  }, [minimumAmount]);
  return (
    <>
      <div className={styles.head}>
        <div style={{ display: "flex", alignItems: "center", gap: "4px" }}>
          <div>{t("actions.available_balance")}</div>
          <Icon name="wallet" size="16" className={cn("styled-icon")} />{" "}
        </div>

        {userBalance[quoteTokenSymbol] && userBalance[baseTokenSymbol] && (
          <div style={{ fontWeight: "500" }}>
            {helper.formatValue(userBalance[quoteTokenSymbol].options, precesion)}
          </div>
        )}
      </div>

      <div>
        <div className={styles.label}>Amount</div>
        <label className={styles.field}>
          <input
            className={styles.input}
            type="text"
            name="amount"
            required
            value={orderValue}
            min={minimumAmount}
            onChange={(evt) => updateIfValid(evt.target.value, setOrderValue)}
          />
          <div className={styles.currency}>{quoteTokenSymbol}</div>
        </label>
      </div>

      <Range
        values={values}
        step={stepPrice}
        min={minPrice}
        max={maxPrice}
        onChange={(values) => {
          updateInputValues(values);
        }}
        renderTrack={({ props, children }) => (
          <div
            onMouseDown={props.onMouseDown}
            onTouchStart={props.onTouchStart}
            style={{
              ...props.style,
              height: "36px",
              display: "flex",
              width: "100%",
            }}
          >
            <div
              ref={props.ref}
              style={{
                height: "2px",
                width: "100%",
                borderRadius: "1px",
                background: getTrackBackground({
                  values,
                  colors: ["#3772FF", "#E6E8EC"],
                  min: minPrice,
                  max: maxPrice,
                }),
                alignSelf: "center",
              }}
            >
              {children}
            </div>
          </div>
        )}
        renderThumb={({ props, isDragged }) => (
          <div
            {...props}
            style={{
              ...props.style,
              height: "18px",
              width: "18px",
              borderRadius: "50%",
              backgroundColor: "#F4F5F6",
              border: "4px solid #777E90",
              boxShadow: "0px 8px 16px -8px rgba(15, 15, 15, 0.2)",
              display: "flex",
              justifyContent: "center",
              alignItems: "center",
            }}
          >
            {isDragged && (
              <div
                style={{
                  position: "absolute",
                  top: "-27px",
                  color: "#FCFCFD",
                  fontWeight: "600",
                  fontSize: "13px",
                  lineHeight: "16px",
                  fontFamily: "Poppins",
                  padding: "2px 6px",
                  borderRadius: "6px",
                  backgroundColor: "#777E90",
                }}
              >
                {values[0].toFixed(0)}%
              </div>
            )}
          </div>
        )}
      />

      <AmountSelectOptions
        percentage={values[0]}
        onPercentageChange={(_value) => {
          updateInputValues([_value]);
        }}
      />

      <div
        className="modify-box d-block"
        style={{
          marginBottom: "24px",
        }}
      >
        <ul>
          <li>
            <p>{t("actions.expiration")}</p>
            <span>60s</span>
          </li>
          <li>
            <p>{t("actions.profit")}</p>
            <span className="blue-clr">{optionsReturnRate}%</span>
          </li>
        </ul>
      </div>

      {isLoggedIn && (
        <div style={{ display: "flex", gap: "10px" }}>
          <button
            className={cn("button-green", styles.button)}
            onClick={() => {
              placeOrder(true);
            }}
          >
            {t("actions.long")}
          </button>
          <button
            className={cn("button-red", styles.button)}
            onClick={() => {
              placeOrder(false);
            }}
          >
            {t("actions.short")}
          </button>
        </div>
      )}
      {!isLoggedIn && (
        <div className={styles.btns}>
          <Link
            className={cn("button", styles.button)}
            activeClassName={styles.active}
            to="/sign-up"
            onClick={(event) => {
              event.preventDefault();
              bride.route("/sign-up.php");
            }}
          >
            {t("actions.sign_up")}
          </Link>
          <Link
            style={{ marginTop: "8px" }}
            className={cn("button-stroke", styles.button)}
            activeClassName={styles.active}
            to="/sign-in"
            onClick={(event) => {
              event.preventDefault();
              bride.route("/sign-in.php");
            }}
          >
            {t("actions.login")}
          </Link>
        </div>
      )}
    </>
  );
};

export default Action;
