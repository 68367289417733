import React, { useState } from "react";

const AmountSelectOptions = ({ percentage, onPercentageChange }) => {
  const defaultPercentages = [10, 20, 50, 75, 100, 0];
  const updatePercentage = (event) => {
    onPercentageChange(parseFloat(event.target.getAttribute("data-value")));
  };

  return (
    <div className="futures-limit">
      <div className="limit-content">
        <form>
          <div className="limit-list">
            <ul>
              {defaultPercentages.map((value) => {
                return (
                  <li key={value}>
                    <a href="#" onClick={updatePercentage} data-value={value}>
                      {value ? value + "%" : "Reset"}
                    </a>
                  </li>
                );
              })}
            </ul>
          </div>
        </form>
      </div>
    </div>
  );
};

export default AmountSelectOptions;
