import bridge from "./php_bridge.js";
import { ORDER_STATUS, PERPETUAL_ORDER_STATUS, TRADING_TYPE } from "./types.js";

const helper = {
  formatValue: (value, precesion = 16) => {
    return value ? parseFloat(parseFloat(value).toFixed(precesion)) : 0;
  },
  isNumberKey: function isNumberKey(evt) {
    var charCode = evt.which ? evt.which : evt.keyCode;
    if (charCode !== 46 && charCode > 31 && (charCode < 48 || charCode > 57))
      return false;

    return true;
  },
  getSecondsGap: (from, to) => {
    // Ensure the inputs are Date objects
    const fromDate = new Date(from);
    const toDate = new Date(to);

    // Calculate the difference in milliseconds
    const differenceInMs = toDate - fromDate;

    // Convert milliseconds to seconds
    const differenceInSeconds = parseInt(differenceInMs / 1000);

    return Math.max(0, differenceInSeconds);
  },

  formatTime: (value) => {
    // Create a new Date object
    const date = new Date(value);

    // Convert to user's local timezone and format the date
    // Extract date components
    const day = String(date.getDate()).padStart(2, "0");
    const month = String(date.getMonth() + 1).padStart(2, "0"); // Months are zero-indexed
    const year = date.getFullYear().toString().substr(2, 2);

    // Extract time components
    const hours = String(date.getHours()).padStart(2, "0");
    const minutes = String(date.getMinutes()).padStart(2, "0");
    const seconds = String(date.getSeconds()).padStart(2, "0");

    // Format the date and time
    const formattedDate = `${hours}:${minutes}:${seconds} ${day}/${month}/${year}`;

    return formattedDate;
  },
  getOrderStatusText: (status) => {
    switch (status) {
      case ORDER_STATUS.pending:
        return "pending";
      case ORDER_STATUS.executed:
        return "executed";
      case ORDER_STATUS.failed:
        return "failed";
      case ORDER_STATUS.cancelled:
        return "cancelled";
      default:
        return status;
    }
  },
  getFutureOrdersStatusLabel: (status) => {
    switch (status) {
      case PERPETUAL_ORDER_STATUS.pending:
        return "pending";
      case PERPETUAL_ORDER_STATUS.executed:
        return "executed";
      case PERPETUAL_ORDER_STATUS.failed:
        return "failed";
      case PERPETUAL_ORDER_STATUS.cancelled:
        return "cancelled";
      default:
        return status;
    }
  },
  isValidNumber: (str) => {
    const regex = /^-?\d+(\.\d+)?$|^-?\d+\.$/;
    return regex.test(str);
  },
  updateIfValid: (value, trigger) => {
    if (helper.isValidNumber(value) || value.length === 0) {
      trigger(value);
    }
  },
  getTradingTypeLabel: (tradingType) => {
    if (tradingType === TRADING_TYPE.spot) {
      return "Spot";
    } else if (tradingType === TRADING_TYPE.futures) {
      return "Futures";
    } else if (tradingType === TRADING_TYPE.options) {
      return "Options";
    }
    return "";
  },
  updateUriSymbol: (tradingType, symbol) => {
    console.log("update called", symbol);
    if (tradingType === TRADING_TYPE.spot) {
      window.history.replaceState(null, "Spot", `/spot/${symbol}`);
    } else if (tradingType === TRADING_TYPE.futures) {
      window.history.replaceState(null, "Futures", `/futures/${symbol}`);
    } else if (tradingType === TRADING_TYPE.options) {
      window.history.replaceState(null, "Options", `/options/${symbol}`);
    } else {
      console.log("unsupported trading type symbol not updated");
    }
    bridge.switchSymbol(symbol);
  },
  setTitle: (title) => {
    document.title = title;
    bridge.setTitle(title);
  },
};

export default helper;
